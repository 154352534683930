.main-content {
    
   .view-title {
        margin: 0 0 1.5rem 0px;
    }
    > hr.MuiDivider-fullWidth {
        border-color: transparent !important;
        margin: 15px 0;
    }

    .dashboard-message {
        margin-top:10px;
    }
}

/******* DESKTOP APP CONTENT *******/

div.app-content-desktop {

    .main-content h3.view-title {
        text-align: left;
    }

    section.job-offers-tabs {
        // height: 0px !important;
        // margin: 0px !important;
    }

    section.search-filters {
        padding-top: 0px !important;
    }
}

/******* MOBILE APP CONTENT *******/

div.app-content-mobile {

    .main-content h3.view-title {
        text-align: center;
    }
}


/******* MOBILE NAV *******/

// role[presentation]
div.mobile-sidebar {
    width: 100% !important;

    .MuiDrawer-paper {
        width: 100% !important;

        a.brand-icon {
            max-height: 80px;

            svg {
                margin: 0 auto;
            }
        }
    }
}

button.auth-button {
    margin-top:5px;
    padding:10px 22px;
}

div.brand-navbar {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

/******* SIDEBAR NAV *******/
ul.sidebar-nav {
    h6 {
        font-size: 14px;
        margin-bottom: 5px;
    }
}

/******* OVERIDE MUI ALERT STYLES *******/
.MuiAlert-filledSuccess {
    background-color: #57A57E !important;
}
.MuiAlert-filledInfo {
    background-color: #2B4C96 !important;
}
.MuiAlert-filledWarning {
    background-color: #EBA302 !important;
}
.MuiAlert-filledError {
    background-color: #C7304B !important;
}

/******* OVERIDE MUI BUTTON STYLES *******/
button.MuiButton-containedPrimary {
    background-color: #2B4C96 !important;
}
button.MuiButton-containedPrimary:hover {
    background-color: #223C78 !important;
}
button.MuiLoadingButton-root.Mui-disabled  {
    background-color: #428BCA !important;
    color: #FFF !important;
}
button.MuiButtonBase-root.Mui-disabled:not(.OnBoarding-Button)  {
    background-color: #D9D9D9 !important;
    color: #A0A0A0 !important;
}
button.MuiIconButton-root  {
    max-width: 38px !important;
    max-height: 38px !important;
}

button.icon {
    min-width: auto !important;
    padding:8px !important;
}

/******* OVERIDE MUI LOADER BUTTON STYLES *******/
div.MuiLoadingButton-loadingIndicator {
    svg {
        color: #FFF !important;
    }
}

.MuiSvgIcon-colorSuccess {
    color: #57A57E !important;
}

/******* MODALS *******/

.MuMuiDialog-paper {
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12) !important;
    border-radius: 6px !important;
}
.modal-medium-title {
    line-height: 2.2rem;
    font-size: 20px;
}

/******* PAPER LISTS *******/

.MuiPopover-paper {
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12) !important;
    border-radius: 6px !important;
}

/******* CARD CONTENT *******/
.card-content {
    padding:30px 20px;
}

/******* Titles and Section with icons ******/
h3 {
    line-height: 2.2rem !important;
    font-size: 1.5rem !important;
}

h4 {
    line-height: 2.2rem !important;
    font-size: 1.25rem !important;
}

h6 {
    line-height: 1.188rem !important;
    font-size: 0.875rem !important;
}

.fc-toolbar-title{
    font-size: 1.5rem !important;
}